<template>
    <div class="container-xxl flex-grow-1 container-p-y">


        <h4 class="fw-bold py-3 mb-4">
            <span class="text-muted fw-light">Ustawienia konta /</span> Bezpieczeństwo
        </h4>

        <div class="row">
            <div class="col-md-12">
                <ul class="nav nav-pills flex-column flex-md-row mb-3">
                    <li class="nav-item"><router-link class="nav-link" to="/user/me"><i class="bx bx-user me-1"></i>
                            Konto</router-link></li>
                    <li class="nav-item"><router-link class="nav-link active" to="/user/me/secuity"><i
                                class="bx bx-lock-alt me-1"></i> Bezpieczeństwo</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/user/me/notifications"><i
                                class="bx bx-bell me-1"></i> Powiadomienia</router-link></li>
                </ul>
                <!-- Change Password -->
                <UserSeciurityChangePassword></UserSeciurityChangePassword>
                
                <UserDeleteAccountCard :user_id="user_id"></UserDeleteAccountCard>

                <!--/ Change Password -->

                <!-- Two-steps verification -->
                <!-- <div class="card mb-4">
                    <h5 class="card-header">Two-steps verification</h5>
                    <div class="card-body">
                        <p class="fw-semibold mb-3">Two factor authentication is not enabled yet.</p>
                        <p class="w-75">Two-factor authentication adds an additional layer of security to your account by
                            requiring more than just a password to log in.
                            <a href="javascript:void(0);">Learn more.</a>
                        </p>
                        <button class="btn btn-primary mt-2" data-bs-toggle="modal" data-bs-target="#enableOTP">Enable
                            two-factor authentication</button>
                    </div>
                </div> -->
                <!-- Modal -->
                <!-- Enable OTP Modal -->
                <div class="modal fade" id="enableOTP" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
                        <div class="modal-content p-3 p-md-5">
                            <div class="modal-body">
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                <div class="text-center mb-4">
                                    <h3 class="mb-5">Enable One Time Password</h3>
                                </div>
                                <h6>Verify Your Mobile Number for SMS</h6>
                                <p>Enter your mobile phone number with country code and we will send you a verification
                                    code.</p>
                                <form id="enableOTPForm" class="row g-3 fv-plugins-bootstrap5 fv-plugins-framework"
                                    onsubmit="return false" novalidate="novalidate">
                                    <div class="col-12 fv-plugins-icon-container">
                                        <label class="form-label" for="modalEnableOTPPhone">Phone Number</label>
                                        <div class="input-group input-group-merge has-validation">
                                            <span class="input-group-text">+1</span>
                                            <input type="text" id="modalEnableOTPPhone" name="modalEnableOTPPhone"
                                                class="form-control phone-number-otp-mask" placeholder="202 555 0111">
                                        </div>
                                        <div class="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                    <div class="col-12">
                                        <button type="submit" class="btn btn-primary me-sm-3 me-1">Submit</button>
                                        <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal"
                                            aria-label="Close">Cancel</button>
                                    </div>
                                    <input type="hidden">
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!--/ Enable OTP Modal -->


                <!-- Recent Devices -->
                <UserSeciurityRecentDevices :user_id="user_id"></UserSeciurityRecentDevices>

                <!--/ Recent Devices -->

            </div>
        </div>
    </div>
</template>
<script>
import UserSeciurityChangePassword from './components/UserSeciurityChangePassword.vue';
import UserSeciurityRecentDevices from './components/UserSeciurityRecentDevices.vue';
import UserDeleteAccountCard from './components/UserDeleteAccountCard.vue';
export default {
    data: function () {
        return {
            user_id: 0,
        }
    },
    components: {
        UserSeciurityChangePassword,
        UserSeciurityRecentDevices,
        UserDeleteAccountCard
    },
    created: function () {
        this.user_id = this.$store.state.user.id

    },
}
</script>